<template>
    <div class="form-group">
        <multiselect
            class="choice_city params-city-status"
            :class="{'form-control' : true, 'is-invalid' : isInvalid(this.country)}"
            v-model="country"
            :options="countriesOptions"
            placeholder="Введіть назву країни"
            @search-change="searchCountries"
            :internal-search="false"
            :max-height="165"
            :options-limit="300"
            :allow-empty="false"
            label="name">
        </multiselect>
        <span :class="['popular-country', {'is-invalid' : euroChecked}]" v-if="showPopularCountries && popularCountriess" v-for="(item, index) in popularCountriess">
        <a href="#" @click.prevent="searchCountries(item.name); choseСountry(item)"
           class="link"
           v-text="item.name+ ', '"></a>
    </span>
        <span v-if="euroToggle" class="float-right insurance__euro-toggle" data-toggle="tooltip" data-placement="bottom" :data-original-title="otherCountry.name">
    </span>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import * as router from "vue-router";

export default {
    components: {Multiselect},

    props: ['euroToggle', 'popularCountries', 'countrySelect', 'showPopularCountriesStore', 'countryStore',],

    data: function () {
        return {
            country: {"id": 60,"name": "Європа","name_ru": "Европа"},
            euroChecked: false,
            countries: [],
            countriesOptions: [ {"id": 60,"name": "Європа","name_ru": "Европа"},
                {"id": 117,"name": "Молдова","name_ru": "Молдова"}],
            popularCountriess: [{"id": 60,"name": "Європа","name_ru": "Европа"},{"id": 117,"name": "Молдова","name_ru": "Молдова"}  ],
            isLoading: false,
            showPopularCountries: true
        };
    },
    watch: {
        country: function (newValue) {
            this.countrySelected();
        },
    },

    created() {
        this.countrySelected();
        if(this.countryStore){
            this.country = this.countryStore;
        }
        if( this.showPopularCountriesStore !== null) {
            this.showPopularCountries = this.showPopularCountriesStore;
        }
        if (this.popularCountries) {
            this.popularCountriess = this.popularCountries;
        }
    },

    computed: {
        otherCountry: function () {
            let name = 'Такої країни не знайдено';
            let results = this.countries.filter((country) => {
                return name.includes(country.name);
            });

            if(results.length === 0) {
                return {
                    id: 0,
                    name: name,
                };
            }

            return results[0];
        },
    },

    methods: {
        searchCountries(query) {
            if(query.length > 1){
                this.fetchCountries(query);
            }
        },

        fetchCountries(query) {
            axios.get('/api/directories/country/find?country=' + query).then(({data}) => {
                this.countries = data.data;
                this.countriesOptions = data.data;
                if(this.countriesOptions.length === 0) {
                    this.countriesOptions.push(this.otherCountry);
                }

            }).catch(err => (console.log(err)));
        },

        choseСountry(value) {
            this.country = value;
            $(".tooltip").remove();
            $('.params-city-status').removeClass('disabled');
        },

        isInvalid(val) {
            return ((val == null || val.id == 0));
        },

        countrySelected () {
            this.countrySelect({
                country: this.country,
            })
        },
    }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
